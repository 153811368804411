
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { isEqual } from "lodash"

type ValuesType = string[] | string

@Component({
  name: "ButtonSelect",
  model: {
    prop: "items",
    event: "change"
  }
})
export default class ButtonSelect extends Vue {
  @Prop() private options!: []
  @Prop() private items!: string[]
  @Prop() private isMultiselect!: false
  @Prop() private cols!: null
  @Prop() private isCustom!: false
  @Prop() private isOneRequired!: false

  @Watch("values")
  public watchValues(data: ValuesType) {
    if (Array.isArray(data) && isEqual(data.sort(), this.items.sort())) {
      return
    }
    if (data === this.items) {
      return
    }
    this.$emit("change", data)
  }

  @Watch("items")
  public watchItems() {
    this.values = this.items
  }

  private _uid!: string
  private values: string[] | string = this.isMultiselect ? [] : ""

  beforeMount() {
    this.values = this.items
  }

  click(event: any, type: any) {
    if (type === "radio" && this.isMultiselect) {
      this.values = []
      event.preventDefault()
      return
    }
    if (this.isOneRequired && this.values.length === 1 && event.target.control.checked) {
      event.preventDefault()
      return
    }
    if (!this.isMultiselect || !Array.isArray(this.values)) return

    const radio = this.options.find((e: any) => e.controlType === "radio")

    if (!radio) return

    const { value } = radio

    if (this.values.includes(value)) {
      this.values = []
      event.preventDefault()
      return
    }
  }

  id(value: string) {
    return `${this._uid}-${value}`
  }

  get type() {
    return this.isMultiselect ? "checkbox" : "radio"
  }

  get wrapperClassNames() {
    return !this.cols ? "select-buttons-flex" : ["select-buttons-grid", `grid${this.cols}`]
  }

  isSelected(value: string) {
    if (Array.isArray(this.values)) {
      return this.values.includes(value)
    }
    return this.values === value
  }
}
