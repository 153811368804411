<template>
  <svg class="icon" :class="`icon-${icon}`">
    <use :xlink:href="`${svgSpriteSrc}#${icon}`" />
  </svg>
</template>

<script>
export default {
  name: "SVGIcon",
  data() {
    return {
      svgSpriteSrc: ""
    }
  },
  mounted() {
    this.svgSpriteSrc = document.getElementById("svg-sprite").getAttribute("src")
  },
  props: {
    icon: {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.icon-recovery,
.icon-trend {
  width: 20px;
  height: 20px;
}

.icon-calendar-outlined {
  width: 14px;
  height: 16px;
}

.icon-calendar {
  width: 18px;
  height: 20px;
}

.icon-chevron-right {
  width: 7px;
  height: 14px;
}

.icon-pencil {
  width: 14px;
  height: 14px;
}

.icon-time {
  width: 14px;
  height: 14px;
}

.icon-zap {
  width: 15px;
  height: 17px;
}

.icon-cross {
  width: 16px;
  height: 16px;
}

.icon-info {
  width: 16px;
  height: 16px;
}

.icon-no-day-arrow {
  width: 20px;
  height: 22px;
}

.icon-alert-circle {
  width: 16px;
  height: 16px;
}

.icon-calendar-white {
  width: 16px;
  height: 18px;
}

.icon-chart {
  width: 16px;
  height: 18px;
}

.icon-refresh-cw {
  width: 18px;
  height: 15px;
}

.icon-thumbs-up {
  width: 16px;
  height: 16px;
}

.icon-meh {
  width: 16px;
  height: 16px;
}

.icon-user {
  width: 16px;
  height: 18px;
}

.icon-intensity {
  width: 22px;
  height: 15px;
}

.icon-stress {
  width: 18px;
  height: 16px;
}

.icon-arrow-down {
  width: 15px;
  height: 8px;
}

.icon-telegram {
  width: 16px;
  height: 16px;
}

.icon-download {
  width: 17px;
  height: 18px;
}
.icon-save {
  width: 18px;
  height: 18px;
}

.icon-edit {
  width: 11px;
  height: 11px;
}

.icon-view {
  width: 11px;
  height: 11px;
}

.icon-clone {
  width: 11px;
  height: 11px;
}

.icon-delete {
  width: 11px;
  height: 11px;
}

.icon-star {
  width: 13.33px;
  height: 12.68px;
}
</style>
