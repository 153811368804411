<template>
  <div>
    <button class="info-btn" @click="open()">
      <svg-icon icon="info" />
    </button>

    <BaseModal v-if="showInfo" :title="title" @close="showInfo = false">
      <h3 slot="body" v-html="text" />
    </BaseModal>
  </div>
</template>

<script>
export default {
  name: "InfoModal",
  props: {
    text: {
      default: ""
    },
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showInfo: false
    }
  },
  methods: {
    open() {
      this.showInfo = true
    }
  }
}
</script>

<style scoped lang="scss">
.info-btn {
  background: transparent;
  border: none;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep {
  .base-modal-footer {
    display: none;
  }
}
</style>
