<template>
  <transition name="base-modal">
    <div class="base-modal-mask" @click.self="$emit('close')">
      <div class="base-modal-wrapper">
        <div class="base-modal-container">
          <div class="base-modal-header">
            <h3 class="title is-4">
              {{ title }}
            </h3>
            <button href="#" class="base-modal-button-close" @click.prevent="$emit('close')">
              <svg-icon icon="cross" />
            </button>
          </div>
          <div class="base-modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="base-modal-footer">
            <slot name="footer">
              <div class="base-modal-default-buttons">
                <slot name="save-button">
                  <button class="button is-red" @click="$emit('save')">Save</button>
                </slot>
                <button class="button is-light" @click="$emit('close')">{{ $t("common.close") }}</button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  beforeMount() {
    document.querySelector(".app-root").classList.add("is-modal-open")
  },
  beforeDestroy() {
    document.querySelector(".app-root").classList.remove("is-modal-open")
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.base-modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  transition: opacity 0.3s ease;
  overflow: auto;
}

.base-modal-wrapper {
}

.base-modal-container {
  max-width: 480px;
  width: auto;
  margin: 0 auto;
  padding: 20px 24px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.base-modal-header h3 {
  margin-bottom: 0;
}

.base-modal-body {
  margin: 20px 0;
}

.base-modal-default-buttons {
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
}

.base-modal-enter {
  opacity: 0;
}

.base-modal-leave-active {
  opacity: 0;
}

.base-modal-enter .base-modal-container,
.base-modal-leave-active .base-modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.base-modal-header {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
}

.base-modal {
  &-button-close {
    background: none;
    border: 0;
    padding: 4px;
    margin-right: -4px;
    height: 24px;
    color: $gray6;
    cursor: pointer;
  }
}
</style>
