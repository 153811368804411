<template>
  <div class="toggle" :class="{ 'is-off': (!selected && !options) || disabled }">
    <p v-if="!$slots.default" :class="{ checked: !selected }">{{ firstOption }}</p>
    <label class="switch">
      <input type="checkbox" :id="`toggle_${id}`" v-model="selected" :disabled="disabled" />
      <span class="slider"></span>
    </label>
    <label v-if="$slots.default" :for="`toggle_${id}`">
      <slot></slot>
    </label>
    <p v-if="!$slots.default" :class="{ checked: selected }">{{ secondOption }}</p>
  </div>
</template>

<script>
export default {
  name: "Toggle",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array
    }
  },
  computed: {
    firstOption() {
      if (this.options) {
        const [option] = this.options
        return option
      }
      return null
    },
    secondOption() {
      if (this.options) {
        const [, option] = this.options
        return option
      }
      return null
    }
  },
  mounted() {
    this.selected = this.value
  },
  watch: {
    value(value) {
      this.selected = value
    },

    selected(value) {
      this.$emit("change", value)
    }
  },
  data() {
    return {
      id: new Date().getTime() + this._uid,
      selected: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 14px;
  flex-shrink: 0;
  @include media("<=tablet") {
    width: 55px;
    height: 24px;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(44, 104, 222, 0.38);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: -3px;
    background-color: $dark-blue;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;

    @include media("<=tablet") {
      height: 30px;
      width: 30px;
    }
  }
}

input:checked + .slider:before {
  -webkit-transform: translateX(90%);
  -ms-transform: translateX(90%);
  transform: translateX(90%);
}

.toggle {
  display: flex;
  align-items: center;
  gap: 10px;

  &.is-off {
    filter: grayscale(1);
    opacity: 0.6;
  }

  & > p:first-child {
    text-align: right;
  }
  p {
    color: $gray6;
    opacity: 0.6;
    display: inline;

    &.checked {
      text-shadow: 0 0 0.01px $gray6;
      opacity: 1;
    }
  }
}
</style>
