<template>
  <transition name="modal">
    <div class="base-modal-mask" @mousedown.self="$emit('close')">
      <div class="base-modal-wrapper">
        <div class="blue-header-modal-container">
          <div class="blue-header-modal-header">
            <div class="field text-left">
              <slot name="header">
                default header
              </slot>
            </div>
            <button href="#" class="blue-header-modal-button-close" @click.prevent="$emit('close')">
              <svg-icon icon="cross" />
            </button>
          </div>
          <div class="blue-header-modal-body">
            <slot name="body">
              default body
            </slot>
          </div>
          <div class="blue-header-modal-footer">
            <slot name="footer"> </slot>
          </div>
        </div>
        <div class="empty"></div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "NewPlanTypeModal",
  beforeMount() {
    document.querySelector(".app-root").classList.add("is-modal-open")
  },
  beforeDestroy() {
    document.querySelector(".app-root").classList.remove("is-modal-open")
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

.base-modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  //align-items: center;
  padding: 200px 20px 20px 20px;
  transition: opacity 0.3s ease;
  overflow: auto;
}

.empty {
  height: 200px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .blue-header-modal-container,
.modal-leave-active .blue-header-modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.blue-header-modal {
  &-container {
    width: 100%;
    max-width: 977px;
    min-width: 977px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    border-radius: 10px;
    overflow: hidden;

    @include media("<desktop") {
      max-width: 750px;
      min-width: 750px;
    }

    @include media("<tablet") {
      max-width: 450px;
      min-width: 450px;
    }

    @include media("<phone") {
      max-width: unset;
      min-width: unset;
    }
  }

  &-button-close {
    background: none;
    border: 0;
    padding: 4px;
    margin-right: -4px;
    height: 24px;
    color: white;
    cursor: pointer;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    background-color: $dark-blue;
    padding: 33px 50px 30px 60px;

    @include media("<tablet") {
      padding: 20px;
    }

    ::v-deep {
      .field {
        width: 50%;

        .label {
          color: white;
          font-size: 24px;
        }
        @include media("<desktop") {
          .label {
            font-size: 20px;
          }
        }
        @include media("<tablet") {
          .label {
            font-size: 18px;
          }
        }
        @include media("<phone") {
          width: 100%;
          margin-bottom: 0;

          .label {
            font-size: 16px;
          }
        }
      }
    }
    .icon-cross {
      width: 12px;
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    padding: 30px 55px;

    &:empty {
      display: none;
    }

    ::v-deep {
      .button {
        gap: 5px;
        margin: 0;
        border-radius: 8px;
        text-transform: uppercase;
        padding: 24px 0;
      }

      .icon-plus {
        width: 9px;
        transform: rotate(45deg);
      }
    }
    @include media("<tablet") {
      padding: 16px;
    }
  }
}
</style>
