<template>
  <div class="select">
    <select v-model="weeklyDay" @change="change" :disabled="isDisabled">
      <option :value="option.value" v-for="option in options" :key="option.value">
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SelectDay",
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: "mon"
    }
  },
  mounted() {
    this.weeklyDay = this.value
  },
  data() {
    return {
      weeklyDay: "mon",
      options: [
        { name: "Monday", value: "mon" },
        { name: "Tuesday", value: "tue" },
        { name: "Wednesday", value: "wed" },
        { name: "Thursday", value: "thu" },
        { name: "Friday", value: "fri" },
        { name: "Saturday", value: "sat" },
        { name: "Sunday", value: "sun" }
      ]
    }
  },
  methods: {
    change() {
      this.$emit("change", this.weeklyDay)
    }
  }
}
</script>

<style scoped></style>
