<template>
  <div class="select">
    <select v-model="dailyTime" @change="change" :disabled="isDisabled">
      <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
export const zeroPad = (num, places) => String(num).padStart(places, "0")

const parseTime = time => {
  const [, sign, hours, minutes] = time.match(/([+-]?)(\d+):(\d+)/)

  return {
    sign,
    hours: Number(hours),
    minutes: Number(minutes)
  }
}

const convertToAmPm = time => {
  const { hours, minutes } = parseTime(time)
  const hoursBeforePm = 12

  const amPm = hours >= hoursBeforePm ? "PM" : "AM"
  const amPmHours = hours > hoursBeforePm ? hours - hoursBeforePm : hours === 0 ? hours + hoursBeforePm : hours
  return `${amPmHours}:${zeroPad(minutes, 2)} ${amPm}`
}

const generateValues = isAdmin => {
  const stepMinutes = isAdmin ? 1 : 30
  const hoursInDay = 24
  const minutesInHour = 60

  const stepsInHour = minutesInHour / stepMinutes

  let currentHour = 0
  let currentMinutes = 0

  return Array.from({ length: hoursInDay })
    .reduce(acc => {
      const textHour = zeroPad(currentHour, 2)

      for (let i = 0; i < stepsInHour; i++) {
        const textMinutes = zeroPad(currentMinutes, 2)

        acc.push(`${textHour}:${textMinutes}`)

        currentMinutes += stepMinutes
      }

      currentHour += 1
      currentMinutes = 0

      return acc
    }, [])
    .map(value => ({ name: convertToAmPm(value), value }))
}

export default {
  name: "SelectTime",
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: "00:00"
    }
  },
  mounted() {
    this.dailyTime = this.value
  },
  data() {
    return {
      dailyTime: "18:00",
      options: generateValues(this.isAdmin)
    }
  },
  methods: {
    change() {
      this.$emit("change", this.dailyTime)
    },
    parseHours(time) {
      const { hours, minutes } = parseTime(time)
      const minutesInHour = 60
      const hoursFromMinutes = minutes / minutesInHour

      const result = (hours + hoursFromMinutes).toFixed(2)

      return Number(result)
    }
  }
}
</script>

<style scoped>
.select select {
  width: 100%;
}
</style>
