<template>
  <div class="workout-chart" v-show="hasData" ref="chart">
    <!--    <div class="wbal-settings-panel">-->
    <!--      <WBalSettings :hideLearnLink="hideLearnLink" alignment="right" />-->
    <!--    </div>-->

    <svg id="workout-svg" width="100%" :height="height" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <mask id="wBalMask">
          <rect :x="x(0)" :y="y(maxValueY) - 2" :width="chartWidth" :height="chartHeight + 2" fill="white" />
        </mask>
      </defs>
      <g class="workout-details-chart-axis" id="workout-axis"></g>
      <g class="lines-y">
        <text
          id="workout-axis-ftp"
          transform="rotate(-90.000000)"
          text-anchor="middle"
          :font-size="fontSize"
          :fill="labelsColor"
          :x="-y(100)"
          :y="12"
        >
          <tspan>FTP</tspan>
        </text>
        <rect
          v-for="(line, index) in linesY"
          :width="line.width"
          :x="line.x"
          :y="line.y"
          :fill="line.fill"
          :height="line.height"
          :key="index"
        />
      </g>
      <path fill="#2C68DE" :d="segmentsPath"></path>
    </svg>
  </div>
</template>

<script>
import Vue from "vue"
import { scaleLinear } from "d3-scale"
import { extent } from "d3-array"
import { area } from "d3-shape"
import { select } from "d3-selection"
import { axisLeft, axisBottom } from "d3-axis"
// import WBalSettings from "@/shared/components/WBalSettings"
import workoutChartHoverPolygons from "@/mixins/workoutChartHoverPolygons"
import { debounce } from "underscore"
import "d3-transition"

const HEIGHT = 300
const MOBILE_HEIGHT = 200
const MAX_VALUE_Y = 200
const PADDING = { top: 10, bottom: 40, left: 70, right: 15 }
const PADDING_MOBILE = { top: 10, bottom: 30, left: 58, right: 10 }
const LABELS_COLOR = "#2B2C2E" // '#D7DADF'
const LINE_GRAY = "#D7DADF"
const LINE_YELLOW = "#ffb825"
const isMobile = () => window.innerWidth <= 767

export default Vue.extend({
  name: "ChartD3",
  mixins: [workoutChartHoverPolygons],
  // components: {
  //   WBalSettings
  // },
  data() {
    return {
      svg: null,
      fullWidth: 0,
      fullHeight: 0,
      maxValueY: MAX_VALUE_Y,
      labelsColor: LABELS_COLOR,
      isMobile: false
      // debouncedScale: () => {}
    }
  },
  props: {
    segments: {
      type: Array,
      default: () => []
    },
    hideLearnLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fontSize() {
      return this.isMobile ? 12 : 16
    },

    padding() {
      return this.isMobile ? PADDING_MOBILE : PADDING
    },

    width() {
      return this.fullWidth
    },

    chartWidth() {
      const { padding } = this
      return this.width ? this.width - padding.right - padding.left : 0
    },

    height() {
      return this.fullHeight
    },

    chartHeight() {
      const { padding } = this
      return this.height ? this.height - padding.top - padding.bottom : 0
    },

    x() {
      const { width, padding } = this

      return scaleLinear()
        .domain(extent(this.segmentsData, ([time]) => time))
        .range([padding.left, width - padding.right])
    },

    y() {
      const { height, maxValueY, padding } = this

      return scaleLinear()
        .domain([0, maxValueY])
        .range([height - padding.bottom, padding.top])
    },

    linesY() {
      const { x, y, chartWidth, maxValueY } = this
      const middle = maxValueY / 2

      const shiftX = 3

      return [
        { width: chartWidth + shiftX, x: x(0) - shiftX, y: y(maxValueY), height: "1", fill: LINE_GRAY },
        { width: chartWidth + shiftX, x: x(0) - shiftX, y: y(middle * 1.25), height: ".6", fill: LINE_GRAY },
        { width: chartWidth + shiftX, x: x(0) - shiftX, y: y(middle * 1.5), height: "1", fill: LINE_GRAY },
        { width: chartWidth + shiftX, x: x(0) - shiftX, y: y(middle * 1.75), height: ".6", fill: LINE_GRAY },
        { width: chartWidth + shiftX, x: x(0) - shiftX, y: y(middle), height: "2", fill: LINE_YELLOW },
        {
          width: chartWidth + shiftX,
          x: x(0) - shiftX,
          y: y(maxValueY - middle * 1.75),
          height: ".6",
          fill: LINE_GRAY
        },
        { width: chartWidth + shiftX, x: x(0) - shiftX, y: y(maxValueY - middle * 1.5), height: "1", fill: LINE_GRAY },
        {
          width: chartWidth + shiftX,
          x: x(0) - shiftX,
          y: y(maxValueY - middle * 1.25),
          height: ".6",
          fill: LINE_GRAY
        },
        { width: chartWidth + shiftX, x: x(0) - shiftX, y: y(0), height: "1", fill: LINE_GRAY }
      ]
    },

    hasData() {
      return !!this.segments.length
    },
    segmentsData() {
      let timeAcc = 0

      return this.segments.reduce((acc, [time, start, end, cadenceStart, cadenceEnd, , , slope]) => {
        acc.push([timeAcc, start, cadenceStart, slope])
        timeAcc = timeAcc + time
        acc.push([timeAcc, end, cadenceEnd])
        return acc
      }, [])
    },
    segmentsPath() {
      const { x, y } = this
      return area()
        .x(([time]) => x(time))
        .y0(y(0))
        .y1(([, value]) => y(value))(this.segmentsData)
    }
  },
  methods: {
    render() {
      const { x, y, width, height, padding } = this

      this.svg = select("#workout-svg")

      const allValues = [0, 25, 50, 75, 100, 125, 150, 175, 200]
      const oddValues = [25, 75, 125, 175]
      const isCenterLine = d => d === 100

      const yAxisLabels = g =>
        g
          .attr("transform", `translate(${padding.left},0)`)
          .call(
            axisLeft(y)
              .tickFormat(value => `${value}%`)
              .tickValues(allValues)
          )
          .call(g => g.select(".domain").remove())
          .call(g =>
            g.selectAll("text").each((value, index, elements) => {
              if (oddValues.indexOf(value) !== -1) {
                elements[index].remove()
              }
            })
          )
          .call(g => g.selectAll(".tick line").remove())
          .call(g =>
            g
              .selectAll(".tick text")
              .attr("class", "workout-details-chart-label")
              .attr("color", value => (isCenterLine(value) ? "#2B2C2E" : LABELS_COLOR))
              .attr("font-weight", value => (isCenterLine(value) ? "bold" : "normal"))
          )

      const xAxis = g =>
        g
          .attr("transform", `translate(0, ${height - padding.bottom})`)
          .call(axisBottom(x).ticks(width / (this.isMobile ? 50 : 100)))
          .call(g => g.select(".domain").remove())
          .call(g => g.selectAll("line").attr("stroke", LABELS_COLOR))
          .call(g =>
            g
              .selectAll("text")
              .attr("class", "workout-details-chart-label")
              .attr("color", LABELS_COLOR)
          )

      const svg = select("#workout-axis")

      svg.selectAll("g").remove()

      svg.append("g").call(yAxisLabels)
      svg
        .append("g")
        .call(xAxis)
        .attr("class", "x-axis")
    },

    onResize() {
      this.isMobile = isMobile()
      this.fullWidth = this.$refs.chart.clientWidth
      this.fullHeight = this.isMobile ? MOBILE_HEIGHT : HEIGHT
      this.render()
    },

    cutMaxLength(event, value) {
      const el = event.target

      if (el.value.length > el.maxLength) {
        this[value] = el.value.slice(0, el.maxLength)
      }
    }
  },
  mounted() {
    this.debouncedScale = debounce(() => this.onResize(), 100)

    window.addEventListener("resize", this.debouncedScale)

    this.onResize()
    // this.$nextTick(() => this.onResize())
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedScale)
    this.removeHoverPolygons()
  }
})
</script>

<style lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.workout-details-chart-label {
  font-family: "Poppins", sans-serif;
  font-size: 16px;

  @include media("<tablet") {
    font-size: 12px;
  }
}

.workout-details-chart-axis {
  .x-axis .tick:last-child line {
    transform: translate(-1px, 0);
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.workout-chart {
  width: 100%;
  padding-top: 2px;
  margin-bottom: 20px;
}

.wbal-settings-panel {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;

  @include media("<tablet") {
    font-size: 12px;
    margin-bottom: 20px;
  }
}
</style>
