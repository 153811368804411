<template>
  <div class="plan-description" ref="description" :style="{ height: getHeight }">
    <div class="cut-area" ref="cutArea" v-html="description"></div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"

export default {
  name: "CutDescription",
  watch: {
    description(newVal) {
      if (newVal) {
        this.$refs.description.scrollTo({ top: 0, behavior: "smooth" })
      }
    }
  },
  props: {
    description: {
      type: String,
      default: ""
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isBlacked() {
      return this.$route.meta.isBlacked
    },
    getHeight() {
      if (this.isBlacked || this.isMobile) return 250 + "px"
      if (this.$route.name === "plan") return 140 + "px"
      return 410 + "px"
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

.plan-description {
  font-size: 14px;
  color: $gray8;
  overflow-y: scroll;
  padding-right: 5px;

  @include media("<desktop") {
    height: unset;
    max-height: 500px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: $light-blue;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $light-blue3;
    border-radius: 15px;
  }
}
</style>

<style lang="scss">
.plan-description {
  ul {
    list-style: inherit;
    padding: 1em 0 1em 30px;
    line-height: 1;

    li {
      margin-bottom: 1em;
    }
  }

  p {
    margin-bottom: 1em;
  }
}
</style>
